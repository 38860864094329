import React from "react";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
const Breadcrumbs = (props) => {
        var breadcrumbs = props.breadcrumbs;
        return (
            <Container fluid className="breadcrumb-container detail-breadcrumb-container">
                <Row>
                    <Col>
                        <Breadcrumb className='d-block'>
                            <Link to="/" variant="none" className="breadcrumb-item">Home</Link>
                            {breadcrumbs?.map((breadcrumb, i, arr) => {                               
                                return (
                                    <Link
                                        key={i}
                                        to={breadcrumb.url + "/"}
                                        variant="none"
                                        className="breadcrumb-item"
                                    >
                                        {breadcrumb.label}
                                    </Link>
                                )
                            })}  
                            {props.title ?                          
                            <Breadcrumb.Item active>{props.title}</Breadcrumb.Item>
                            :""}
                        </Breadcrumb>
                    </Col>
                </Row>
            </Container>
        )
}

export default Breadcrumbs
