import { graphql } from "gatsby"
import React from "react"
import BannerLandingSearch from "../components/BannerLandingSearch/BannerLandingSearch"
import FeaturedPropertiesSlider from "../components/FeaturedPropertiesSlider/FeaturedPropertiesSlider"
import Footer from "../components/Footer/Footer"
import FullWidthBanner from "../components/FullWidthBanner/FullWidthBanner"
import Header from "../components/Header/Header"
import LandingAboutModule from "../components/LandingAboutModule/LandingAboutModule"
import GoogleSingleMap from "../components/maps/google/single"
import OtherAreaGuides from "../components/OtherAreaGuides/OtherAreaGuides"
import SEO from "../components/Seo/seo"

const AreaguideDetailPage = ({ data }) => {
  const areaguide = data.glstrapi.areaGuide

  return (
    <div className="areaguide-detail-page-wrapper">
      <Header layout={"landing_with_search_template"} />
      <SEO
        title={areaguide.name+" Area Guide"}
        description={"Know more about "+areaguide.name+" here. Contact one of our property consultants for assistance in finding the right properties for you in "+areaguide.name+"."}
      />
      <BannerLandingSearch
        title={areaguide.name + " Community Guide"}
        id={areaguide.id}
        slug={areaguide.slug}
        imagetransforms={areaguide.imagetransforms}
        bgImg={areaguide.banner_image}
        stats={areaguide.icon_stats?.stats}
        areaguideBanner
      />
      {/* <img src={areaguide.banner_image.url} alt="" /> */}
      <LandingAboutModule
        aboutContent={areaguide.areaguide_content}
        team={areaguide.team}
      />
      <FeaturedPropertiesSlider />
      <div className="areaguide-map">
        <GoogleSingleMap
          lat={areaguide?.latitude}
          lng={areaguide?.longitude}
          height={"460px"}
        />
      </div>
      <OtherAreaGuides currentAreaID={areaguide.id} />
      {areaguide?.full_width_banner_modules?.map(module => {
        return (
          <FullWidthBanner module={module} isAreaGuide/>
        )
      })}
      <Footer />
    </div>
  )
}

export default AreaguideDetailPage

export const pageQuery = graphql`
  query GetAreaguidesPage($articleId: ID!) {
    glstrapi {
      areaGuide(id: $articleId, publicationState: LIVE) {
        id
        seo_meta {
          title
          description
        }
        slug
        areaguide_content {
          description
          heading
          accordianContent {
            description
            title
          }
        }
        banner_image {
          url
        }
        full_width_banner_modules {
          select_module
          title
          heading
          banner_image {
            url
          }
          ctas {
            theme
            target
            label
            id
            custom_link
          }
        }
        icon_stats {
          stats {
            Icon
            Title
            description
            id
          }
        }
        imagetransforms
        latitude
        longitude
        name
        publish
        shortDescription
        team {
          tile_image {
            url
          }
          video_url
          title
          email
          slug
          name
          designation
          mobile_no
        }
      }
    }
  }
`
