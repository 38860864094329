import React from "react"
import { getSrc } from "gatsby-plugin-image"
import { AltSiteName } from "./utils"
const GetGatsbyImage = props => {
  var imagesource = props.image.url_sharp
  return (
    <img
      src={getSrc(imagesource)}
      alt={
        props.image.alternativeText
          ? props.image.alternativeText + AltSiteName
          : props.fallbackalt + AltSiteName
      }
      className={props.className}
    />
  )
}
export default GetGatsbyImage
